code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.lang-flag img {
  border-radius: 0;
}

#cf-turnstile {
  margin-top: 10px;
  width: 100% !important;
  text-align: center;
}

.formio-component-storage {
  display: none;
}
