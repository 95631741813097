[dir=rtl] {
    text-align: right;
}

[dir=rtl] .formio-component.formio-component-selectboxes > .form-group,
[dir=rtl] .formio-component.formio-component-radio > .form-group {
    text-align: right;
}

[dir=rtl] .formio-component.formio-component-datetime .flatpickr-input {
    text-align: right;
}

[dir=rtl] .choices:after {
    right: unset;
    left: 11.5px;
}

[dir=rtl] .choices .choices__list .choices__item {
    text-align: right;
}

[dir=rtl] .button-custom-react {
    text-align: right;
    float: right;

}

[dir=rtl] .drag-copy {
    text-align: right;
    .fa {
        margin-right: 0 !important;
        margin-left: 5px;
    }

}

[dir=rtl] .form-check > label >  span {
    padding-right: 20px;
}

[dir=rtl] .form-check > label >  input {
    float: right;
}


[dir=rtl] .choices .choices__list .choices__item img {
    float: right;
}

[dir=rtl] .choices .choices__list .choices__item button.choices__button {
    left: 0;
    right: unset;
    margin-right: unset;
    margin-left: 25px;
    overflow: hidden;
}

[dir=rtl] .choices .choices__list.choices__list--dropdown > input.choices__input {
    text-align: right;
}

[dir=rtl] .choices .choices__list.choices__list--dropdown .choices__item--selectable {
    padding-right: 10px;
    padding-left: 0;
}

[dir=rtl] .choices .choices__list.choices__list--multiple {
    float: right;
}

[dir=rtl] .choices .choices__list.choices__list--multiple .choices__item button.choices__button {
    float: left;
    margin: 0 8px 0 -4px;
    padding-left: unset;
    padding-right: 16px;
    border-left: none;
    border-right: 1px solid #008fa1;
}

[dir=rtl] .component-btn-group {
    right: unset;
    left: 0;
}

[dir=rtl] {
    @media (min-width: 992px) {
        .sidebar.sidebar-fixed:not(.sidebar-end) {
            left: unset;
        }
    }

    @media (min-width: 992px) {
        .sidebar.hide:not(.sidebar-end) {
            margin-right: calc(-1* var(--cui-sidebar-width));
        }
    }

    .wrapper {
        width: 100%;
        padding-right: var(--cui-sidebar-occupy-start, 0);
        padding-left: var(--cui-sidebar-occupy-end, 0);
        will-change: auto;
        transition: padding 0.15s;
    }

    .sidebar-nav .nav-icon {
        margin-right: 0;
        margin-left: var(--cui-sidebar-nav-link-icon-margin);;
    }

    svg:not(:root).svg-inline--fa, svg:not(:host).svg-inline--fa {
        margin-right: 0;
        margin-left: 0.5rem;
    }

    .dropdown-menu.show li {
        text-align: right;
    }

    .custom-table {
        direction: ltr;
    }

    [type=email] {
        direction: rtl;
    }

    .builder-sidebar .btn {
        text-align: start;
    }
}
