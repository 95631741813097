@import "~bootstrap/dist/css/bootstrap.css";
@import "~@formio/js/dist/formio.full.min.css";
@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

body {
  background-color: var(--cui-tertiary-bg);
  margin: 0;
  font-family: "Cairo", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.body {
  padding: 0 1.5rem;
}

.nav-link a {
  color: inherit;
  text-decoration: none;
}

.formio-brand {
  font-size: 1rem;
  display: flex;
  align-items: center;
}

.formio-brand > * {
  margin-right: 5px;
}

.navbar-brand a {
  color: inherit;
  text-decoration: none;
}

.react-logo {
  color: #0a7ea3;
}

div.spacer {
  margin: 0.5rem 0;
  line-height: 0;
}

.formio-component-submit {
  margin: 2px;
  padding-top: 15px;
}

.formio-component-customDefaultValuePanel {
  display: none;
}

.formio-component-calculateValuePanel {
  display: none;
}

#snackbar {
  text-align: center; /* Centered text */
  border-radius: 2px; /* Rounded borders */
  position: fixed; /* Sit on top of the screen */
  z-index: 1; /* Add a z-index if needed */
  left: 50%; /* Center the snackbar */
  bottom: 30px; /* 30px from the bottom */
}

.drag-and-drop-alert {
  background-color: #d1e7dd;
  border: 1px solid rgba(0, 0, 0, 0.4);
}

.formcomponents .formcomponent {
  background-color: #d1e7dd;
  color: #000000;
  border: 1px solid rgba(0, 0, 0, 0.4);
  font-size: 15px;
  width: 100%;
}

.formcomponents .formcomponent:active {
  background-color: #d1e7dd;
  border: 1px solid #000000;
  color: #000000;
}

.gu-mirror {
  background-color: #d1e7dd !important;
  border: 1px solid #000000 !important;
}

.auth::before {
  content: "";
  background-image: url("assets/images/CDF_Background.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1; /* Adjust the opacity as needed */
  z-index: -1;
}

.auth-box {
  background-color: rgba(165, 162, 162, 0.18);
}

.sidebar-nav .nav-item {
  font-size: 20px;
}

.form-widget .card .card-body {
  padding: 1rem !important;
}

:lang(ar) .table > tbody > tr > td.cell-align-left {
  text-align: right;
}

.fa-times-circle-o:after {
  content: "\00D7";
  color: white;
  font-size: small;
}

.datagrid-table thead th:first-child,
.datagrid-table tbody td:first-child,
.formio-component-customDefaultValuePanel,
.formio-component-calculateValuePanel{
  display: none !important;
}

.formio-component-survey {
  overflow: auto;
}

.formio-component-survey table th, .formio-component-survey table td {
  word-break: auto-phrase;
  white-space: normal;
}

.formio-component-survey table {
  width: 100%;
  table-layout: auto;
}
